<template>
  <div class="allmsg">
    <div class="loginclass">
      <div>萝卜猎手推广账号登录</div>
      <div class="thelogin">
        <div @click="loginmeth">{{ loginmsg }}</div>
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="redmsgclass">
      <div>适用萝卜猎手营销用户</div>
      <div>使用企业端账号可直接登录</div>
    </div>

    <div v-show="loginindex == 1" class="passclass">
      <div class="pass1">
        <input v-model="phone" placeholder="请输入手机号码" />
      </div>
      <div class="pass2">
        <!-- <div>请输入验证码</div> -->
        <input v-model="code" placeholder="请输入验证码" />
        <div @click="cligetcode" class="code">
          {{ getcode == "获取验证码" ? getcode : `请${getcode}秒后重发` }}
        </div>
      </div>
    </div>

    <div v-show="loginindex == 2" class="passclass">
      <div class="pass1">
        <!-- <div>请输入手机号码</div> -->
        <input v-model="phone" placeholder="请输入手机号码" />
      </div>
      <div class="pass1">
        <!-- <div>请输入密码</div> -->
        <input type="password" v-model="password" placeholder="请输入密码" />
      </div>
    </div>

    <buttones class="bo" :msg="bumsg"></buttones>
    <!-- <div class="box">
      <van-checkbox checked-color="#FBA110" v-model="checked"
        >我已阅读并同意<a href="/serviceprotocol">萝卜猎手服务协议</a>和<a
          href="/authorizationprotocol"
          >授权声明</a
        ></van-checkbox
      >
    </div> -->
  </div>
</template>
<script>
import buttones from "../../components/botton/index";
import { Dialog } from "vant";
var intervalID;
export default {
  components: {
    buttones,
  },
  data() {
    return {
      bumsg: "登录",
      phone: "",
      checked: false,
      getcode: "获取验证码", //获取验证码
      code: "", //验证码
      loginindex: "1", //登陆方式
      loginmsg: "密码登录",
      password: "", //密码
    };
  },
  watch: {
    code(oldmsg) {
      if ((oldmsg + "").length > 6) {
        this.code = (oldmsg + "").substr(0, 6);
      }
    },
  },
  methods: {
    /**
     * 点击切换登录方式
     */
    loginmeth() {
       
      if (this.loginindex == "1") {
        this.loginmsg = "验证码";
        this.loginindex = "2";
      } else {
        this.loginindex = "1";
        this.loginmsg = "密码登录";
      }
    },
    /**
     * 点击登录按钮
     */
    clickmeth() {
       

      if (this.loginindex == 1) {
        if (this.phone == "") {
          Dialog({ message: "请输入正确手机号" });
          return;
        }
        if (!/^1[3-9]\d{9}$/.test(this.phone)) {
          Dialog({ message: "请输入正确手机号" });
          return;
        }
        if (this.code == "") {
          Dialog({ message: "请输入正确验证码" });
          return;
        }

        this.codelogin();
      } else if (this.loginindex == 2) {
        if (this.phone == "") {
          Dialog({ message: "请输入正确手机号" });
          return;
        }
        if (!/^1[3-9]\d{9}$/.test(this.phone)) {
          Dialog({ message: "请输入正确手机号" });
          return;
        }
        if (this.password == "") {
          Dialog({ message: "请输入密码" });
          return;
        }

        this.passlogin();
      }
    },

    /**
     * 验证码登录
     */
    codelogin() {
      this.$toast.loading();
      this.$http
        .post("/firm/v1/login/login_phone", {
          reqType: "login",
          mobile: this.phone, //手机号码
          mobile_code: this.code, //验证码
          channel: "1", //是否是渠道端登录
		  login_type: 1
        })
        .then((res) => {
           
          const arr = JSON.parse(res.data);

          this.$toast.success("登录成功");
          sessionStorage.setItem("accessToken", arr.data.accessToken);
          sessionStorage.setItem("token", arr.data.token);
          sessionStorage.setItem("userId", arr.data.id);
          sessionStorage.setItem("phone", arr.data.mobile);
          sessionStorage.setItem("username", arr.data.username);
          this.$router.push("/home");
        });
    },

    /**
     * 密码登录
     */
    passlogin() {
      this.$toast.loading();
      this.$http
        .post("/firm/v1/login/login", {
          reqType: "login",
          mobile: this.phone,
          password: this.password,
          channel: "1", //是否是渠道端登录
		  login_type: 1
        })
        .then((res) => {
           
          const arr = JSON.parse(res.data);
           
          if (arr.code === "6007" || arr.code === "6006") {
            this.$toast.fail("手机号或密码错误");
            return;
          }
          this.$toast.success("登录成功");
          sessionStorage.setItem("accessToken", arr.data.accessToken);
          sessionStorage.setItem("token", arr.data.token);
          sessionStorage.setItem("userId", arr.data.id);
          sessionStorage.setItem("phone", arr.data.mobile);
          sessionStorage.setItem("username", arr.data.username);
          this.$router.push("/home");
        })
        .catch((err) => {
           
        });
    },

    /**
     * 获取验证码
     */
    cligetcode() {
      if (this.getcode != "获取验证码") {
        return;
      }
      if (this.phone == "") {
        Dialog({ message: "请输入正确手机号" });
        return;
      } else if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        Dialog({ message: "请输入正确手机号" });
        return;
      }

       
      this.timeing();

      //获取验证码
      this.sendcode();
    },

    timeing() {
      this.getcode = 60;
      intervalID = setInterval(() => {
        this.getcode--;
         
        if (this.getcode < 1) {
          clearInterval(intervalID);
          this.getcode = "获取验证码";
        }
      }, 1000);
    },

    /**
     * 发送验证码
     */
    sendcode() {
      this.$http
        .post("/firm/v1/login/valid_send", {
          reqType: "login",
          mobile: this.phone,
          source: "login",
		  login_type: 1
        })
        .then((res) => {
           
          this.$toast(JSON.parse(res.data).msg);
        });
    },
  },
};
</script>
<style scoped>
.redmsgclass {
  font-size: 0.28rem;
  color: #f55613;
  margin: 0.48rem;
  margin-top: 0.29rem;
  margin-bottom: 0.62rem;
  font-weight: bold;
}
.box >>> a {
  color: #f55613;
}
.box {
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
  line-height: 0.52rem;
  font-size: 0.26rem;
}
.box .box1 {
  margin-left: 0.15rem;
  width: 4.42rem;
  height: 0.52rem;
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 0.52rem;
}
.box p {
  margin-left: 0.7rem;
  width: 1.04rem;
  height: 0.52rem;
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f55613;
  line-height: 0.52rem;
}
.bo {
  margin-top: 3rem;
}
.code {
  color: #f55613;
}
.pass1,
.pass2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0;
  border-bottom-color: #ebeef5;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

input {
  border: none;
  text-align: left;
}
.passclass {
  font-size: 0.32rem;
  margin: 0.48rem;
}
.thelogin {
  display: flex;
  align-items: center;
}
.loginclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.1rem 0.48rem;
  margin-bottom: 0;
}
.allmsg {
  background-color: #ffffff;
  overflow: hidden;
  padding-bottom: 2rem;
}
.loginclass {
  font-size: 0.46rem;
  font-weight: bold;
}
.loginclass > div:nth-child(2) {
  color: #bcbcbc;
  font-size: 0.34rem;
  font-weight: 500;
}
</style>